import React from 'react';
import { Row, Col } from 'react-bootstrap';



// Images
import picture1 from "~images/blog/img/blogpost2.png";
// import picture2 from "~images/blog/img/eatblog2.png";
// import picture3 from "~images/blog/img/eatblog3.png";
// import picture4 from "~images/blog/img/eatblog4.png";
// import picture5 from "~images/blog/img/eatblog5.png";
// Images
// import Avatar from "~images/blog/avatar.jpg";
// import PostSlide_1 from "~images/blog/post-slide-1.jpg";
// import RecentPost_2 from "~images/blog/recent-post-2.jpg";
// import RecentPost_3 from "~images/blog/recent-post-3.jpg";
// import People_1 from "~images/blog/people-1.jpg";
// import People_2 from "~images/blog/people-2.jpg";
// import PeopleNoImage from "~images/blog/people-no-image.jpg";

const BlogDetails2 = (props) => {
    return (
        <Col className="col-12 col-lg-8 res-margin">
						
            {/* <!-- Article --> */}
            <article className="row blog-post">
				<div className="col-12">
				
						<h3>
							<a href="blog-post"></a>
						</h3>
					<header>
					
						<h3>
							<a href="blog-post">Benefits of eating homely food </a>
						</h3>
                        <p>
                        It’s not always easy to prepare all your meals at home and for most people who love to cook sometimes there isn't enough time. Many others, on the other hand, don’t care about preparing and eating at home because they have not taken the time to understand the advantages and health benefits of homemade foods. In our life food plays an important role, as it provides us with vital vitamins & minerals. It is necessary that which foods we choose to eat should be healthy and clean food. And what’s better than having homemade food? 
                        </p>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							{/* <span><a href="/">Menu Structure</a></span> */}
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={picture1} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Here are some benefits of homemade meals.  
					</p>

					

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Control the quality   </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							{/* <span><a href="/">Menu Structure</a></span> */}
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					{/* <figure>
						<img src={picture2} className="img-responsive img-rounded" alt="" />
					</figure> */}

					<p>
					Homemade food means that you can have full control of what goes into your meals. Eating pre-prepared products, or takeout food can mean consuming low-quality ingredients, which can be used purely for reasons of cost or convenience for the manufacturer. Eating homely meals enables you to avoid the numerous additives and preservatives that can go into food nowadays and instead consume highly nutritional meals.  
					</p>


				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Eat a healthier diet  </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							{/* <span><a href="/">Menu Structure</a></span> */}
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					{/* <figure>
						<img src={picture3} className="img-responsive img-rounded" alt="" />
					</figure> */}

					<p>
					Many of the foods you eat in restaurants or the ones you buy from the supermarkets contain a lot of sugar, fats, and salt. When you eat fresh homemade food, you can control what your meals have in them. By eating homely food, you can have a balanced diet. For instance, you can use olive oil for frying instead of butter or other unhealthy oil.  
					</p>

					

				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Enjoy fresh ingredients  </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							{/* <span><a href="/">Menu Structure</a></span> */}
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					{/* <figure>
						<img src={picture4} className="img-responsive img-rounded" alt="" />
					</figure> */}

					<p>
					Commercially prepared products are often transported and stored. They contain preservatives and other ingredients to slow down the rate of deterioration in quality and taste. Eating homely food means that you can always cook with fresh ingredients, resulting in your food being both tastier and more nutritious.   
					</p>


				</div>
			</article>


            <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Healthy eating habits </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							{/* <span><a href="/">Menu Structure</a></span> */}
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					{/* <figure>
						<img src={picture5} className="img-responsive img-rounded" alt="" />
					</figure> */}

					<p>
					Did you know that when you want to maintain a healthier lifestyle, one you must include for a better result is your eating habit? When you find it difficult to control what you eat, the quantity, and the time you eat, your life will be in trouble. When you patronize homemade food, you will discover many healthy recipes, learn about many types of food and create a meal plan and stick to the plan.  
					</p>
					<p>Always remember that homemade food is the best way to be healthy, that’s to eat homemade dishes and stay strong and healthy. After all, good homemade food always puts you in the mood. </p>
					

				</div>
			</article>
            
{/*             

            <div className="post-footer single-post-footer clearfix">
                
             
                <div className="tags">
                    <a href="/" className="tag">sunset</a> 
                    <a href="/" className="tag">venice</a> 
                    <a href="/" className="tag">canal</a>
                    <a href="/" className="tag">sea</a>
                    <a href="/" className="tag">gondola</a>
                </div>
                        
            
                <div className="share-panel share-btn">
                    <p><i className="fas fa-share-alt"></i>Share</p>
                    <ul>
                        <li>
                            <a href="/">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-pinterest"></i>
                            </a>
                        </li>
                        <li>
                            <a href="/">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                
                <ul className="post-counters hidden-xs">
                    <li>
                        <a href="/">
                            <i className="far fa-comment"></i> 12
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <i className="far fa-heart"></i> 5
                        </a>					
                    </li>
                </ul>

            </div>
            
 
            <hr />
            
     
            <div className="post-author text-center">

                <div className="avatar">
                    <a href="/">
                        <img src={Avatar} className="avatar-100 rounded-circle" width="100" height="100" alt="" />
                    </a>
                </div>

                <div className="description">
                    <span>
                        About Author
                    </span>
                    <h4>
                        <a href="/">AthenaStudio</a>
                    </h4>
                    <p>
                        Quisque rhoncus lectus sit amet ante iaculis maximus. 
                        Vivamus eget sodales purus. 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Donec nec odio nulla.
                    </p>
                </div>

            </div>
            
            
            <div className="post-navigation-wrapper">

                <Row className="divider">
                    <Col className="col-12">

                        <nav className="navigation post-navigation">
                            <div className="nav-links">
                                <div className="nav-previous">
                                    <a href="/">
                                        <img src={RecentPost_2} alt="" />
                                        <div>
                                            <span>Previous Article</span>
                                            Forest Highway With Green Leaves
                                        </div>
                                    </a>
                                </div>
                                <div className="nav-next">
                                    <a href="/">
                                        <img src={RecentPost_3} alt="" />
                                        <div>
                                            <span>Next Article</span>
                                            Aerial Photography of Snowy Mountain Ranges
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </nav>

                    </Col>
                </Row>

            </div>
            
            
            <hr />
            
       
            <div className="comments">

                <Row>
                    <Col className="col-12">
                        <header>
                            <h3>3 Comments</h3>
                        </header>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12 clearfix">

                
                        <div className="user-comment">

                            <div className="user-comment-inner">
                                <div className="image">
                                    <img src={People_1} className="img-responsive rounded-circle" alt="" />
                                </div>

                                <div className="details">
                                    <div className="info">
                                        <span className="author">Rose Johnson</span>
                                        <span className="date"><span>Posted on</span> October 30, 2020</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Quisque vulputate lorem a nisl faucibus, eu consectetur leo maximus. Nulla facilisis nisi nunc, ac tempor ipsum dapibus non. Curabitur non iaculis dui, eu tempus ipsum. Fusce ut porta urna. In sit amet pharetra ligula. Ut posuere quis elit laoreet efficitur.
                                        </p>
                                    </div>
                                    <div className="reply">
                                        <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                    </div>
                                </div>
                            </div>

                        
                            <div className="user-comment bypostauthor">

                                <div className="user-comment-inner">
                                    <div className="image">
                                        <img src={People_2} className="img-responsive rounded-circle" alt="" />
                                    </div>

                                    <div className="details">
                                        <div className="info">
                                            <span className="author">Billie James</span>
                                            <span className="date"><span>Posted on</span> November 3, 2020</span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum fermentum ligula ut nisi mattis, ac laoreet odio pulvinar. Vivamus consequat.
                                            </p>
                                        </div>
                                        <div className="reply">
                                            <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                       
                        <div className="user-comment">

                            <div className="user-comment-inner">
                                <div className="image">
                                    <img src={PeopleNoImage} className="img-responsive rounded-circle" alt="" />
                                </div>

                                <div className="details">
                                    <div className="info">
                                        <span className="author">Ashley Swift</span>
                                        <span className="date"><span>Posted on</span> October 28, 2020</span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Vivamus tincidunt bibendum urna eu auctor. Praesent sit amet tempor nisl. Duis eu enim eleifend, hendrerit dolor ut, tempus diam. Mauris porttitor, purus vel elementum porta, tortor diam vestibulum enim, in viverra metus libero nec mi. Etiam pretium velit.
                                        </p>
                                    </div>
                                    <div className="reply">
                                        <a href="#comment-form"><i className="fas fa-reply"></i> Reply</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </Col>
                </Row>

            </div> */}

            {/* <!-- Leave a comment --> */}
            <div className="comment-form" id="comment-form">

                <Row>
                    <Col className="col-12">
                        <header>
                            <h3>Leave a Comment</h3>
                            <p>Your email address will not be published or shared.</p>
                        </header>
                    </Col>
                </Row>

                <Row>

                    {/* <!-- Fields --> */}
                    <Col className="col-12 col-lg-6">
                        <div className="form-group mt-2 mb-3">
                            <input type="text" name="name" className="form-control field-name" placeholder="Name" />
                        </div>
                    </Col>

                    <Col className="col-12 col-lg-6">
                        <div className="form-group mt-2 mb-3">
                            <input type="email" name="email" className="form-control field-email" placeholder="Email" />
                        </div>
                    </Col>
                
                </Row>
                
                <Row>

                    <Col className="col-12 col-lg-12">
                        <div className="form-group mt-2 mb-3">
                            <textarea name="comment" className="form-control field-comment" rows="4" placeholder="Comment"></textarea>
                        </div>
                    </Col>

                </Row>

                <Row>
                    
                    {/* <!-- Submit button --> */}
                    <Col className="col-12 col-lg-12 mt-2">
                        <button type="submit" className="btn btn-default" id="contact-submit">Post Comment</button>
                    </Col>
                    
                </Row>

            </div>
            
        </Col>
    );
}

export default BlogDetails2;