import React from 'react';

import Layout from "../components/Sections/Layout";
import Loader from "../components/Sections/Loader";
import Header from "../components/Sections/Header";
import PageTitle2 from "../components/BlogPost/PageTitle2";
import BlogContainer from "../components/Blog/BlogContainer";
import BlogDetails2 from "../components/BlogPost/BlogDetails2";
import Sidebar from "../components/Blog/Sidebar";
import Footer from "../components/Sections/Footer";
import ToTop from "../components/Sections/ToTop";

const BlogPost2 = () => (

    <Layout 
        pageTitle="EatCo - Blog Post" 
        colorSchema="../colors/orange.css"
    >
        <Loader />
        <Header />
        <PageTitle2 />

        <BlogContainer>
            <BlogDetails2 />
            <Sidebar />
        </BlogContainer>

        <Footer />
        <ToTop />
    </Layout>

)

export default BlogPost2;